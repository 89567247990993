// Generated by Framer (03f754e)

import { addFonts, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-42otj"

const variantClassNames = {ChRQYFeiP: "framer-v-15dwrif"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "ChRQYFeiP", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-15dwrif", className, classNames)} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"ChRQYFeiP"} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "rgba(255, 255, 255, 0.6)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "rgb(255, 135, 15)", borderBottomLeftRadius: 16, borderBottomRightRadius: 16, borderTopLeftRadius: 16, borderTopRightRadius: 16, boxShadow: "inset 0px 1px 9px 5px rgba(255, 255, 255, 0.37), 0px 2px 7px 0px rgba(255, 135, 15, 0.25)", ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO1NGIFBybyBEaXNwbGF5IEJvbGQ=", "--framer-font-family": "\"SF Pro Display Bold\", \"SF Pro Display Bold Placeholder\", \"-apple-system\", \"BlinkMacSystemFont\", sans-serif", "--framer-font-size": "18px", "--framer-letter-spacing": "-0.02em", "--framer-line-height": "1.65em", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))", "--framer-text-transform": "inherit"}}>Jeg vil have adgang nu</motion.p></React.Fragment>} className={"framer-4m5t8z"} fonts={["CUSTOM;SF Pro Display Bold"]} layoutDependency={layoutDependency} layoutId={"jjqWysaej"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-42otj.framer-18zr3rw, .framer-42otj .framer-18zr3rw { display: block; }", ".framer-42otj.framer-15dwrif { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 2px; height: min-content; justify-content: center; overflow: hidden; padding: 7px 18px 7px 18px; position: relative; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-42otj .framer-4m5t8z { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-42otj.framer-15dwrif { gap: 0px; } .framer-42otj.framer-15dwrif > * { margin: 0px; margin-left: calc(2px / 2); margin-right: calc(2px / 2); } .framer-42otj.framer-15dwrif > :first-child { margin-left: 0px; } .framer-42otj.framer-15dwrif > :last-child { margin-right: 0px; } }", ".framer-42otj[data-border=\"true\"]::after, .framer-42otj [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 43
 * @framerIntrinsicWidth 213.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerfRgQm3adb: React.ComponentType<Props> = withCSS(Component, css, "framer-42otj") as typeof Component;
export default FramerfRgQm3adb;

FramerfRgQm3adb.displayName = "Adgang";

FramerfRgQm3adb.defaultProps = {height: 43, width: 213.5};

addFonts(FramerfRgQm3adb, [{explicitInter: true, fonts: [{family: "SF Pro Display Bold", source: "custom", url: "https://framerusercontent.com/assets/e2xSj9IcJ8dsIvELAZDx3LfWno.woff2"}]}], {supportsExplicitInterCodegen: true})